import { Config } from '../../interfaces/config.interface';
import Logo from './logoCessi.png';

const config: Config = {
  logo: Logo,
  title: 'Portal de talentos',
  name: 'CESSI',
  map_hide: true,
  ods_profile: false,
  catalogTalents: true,
  has_questions_of_interest: true,
  withState: true,
  has_reminder: true,
};

export default config;
